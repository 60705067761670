export function module_common() {
	$(function () {
    //--------------------------------------------------------
    // Resize Event
    //--------------------------------------------------------
    commonResize();

    var timer = false;
    var count = 0;
    var winWidth = $(window).width();
    var winHeight = $(window).height();
    var winWidth_resized;
    var winHeight_resized;

    $(window).on("resize", function () {
      // リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
      if (timer !== false) {
        clearTimeout(timer);
      }

      // 放置時間が指定ミリ秒以上なので処理を実行
      timer = setTimeout(function () {
        // リサイズ後のウインドウの横幅・高さを取得
        winWidth_resized = $(window).width();
        winHeight_resized = $(window).height();

        // リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
        if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
          commonResize();

          // 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
          winWidth = $(window).width();
          winHeight = $(window).height();
        }
      }, 200);
    });

    //共通リサイズイベント
    function commonResize() {
      //timerをクリア、countをリセット
      clearTimeout(timer);
      count = 0;

      $(".gNav").removeClass("fixed");

      //ヘッダー高さを取得
      var headerHeight = $(".header").outerHeight();
      //gNavのメニューの固定を解除
      $(window).on("load scroll", function () {
        $(".gNav").removeClass("fixed");
      });

      $(window).on("load scroll", function () {
        if ($(this).scrollTop() > headerHeight) {
          $(".header").addClass("headerLine");
        } else {
          $(".header").removeClass("headerLine");
        }
      });

      //--------------------------------------------------------
      // スマホ・タブレット時メニュー
      //--------------------------------------------------------
      var state = false;
      var scrollpos;

      $(".spMenu__btn").on("click", function (event) {
        //event.preventDefault();

        if (state == false) {
          // 背景コンテンツ固定
          scrollpos = $(window).scrollTop();
          $("body").addClass("fixed").css({
            top: -scrollpos,
          });

          if (scrollpos >= 130) {
            $(".header").addClass("scrollActive");
          }

          // グローバルメニュー表示
          $(".gNav, .gNav__inner, .spMenu__btn").addClass("active");
          state = true;
        } else {
          // 背景コンテンツ固定 解除
          $("body").removeClass("fixed").css({
            top: 0,
          });
          window.scrollTo(0, scrollpos);

          $(".header").removeClass("scrollActive");

          // グローバルメニュー非表示
          $(".gNav, .gNav__inner, .spMenu__btn").removeClass("active");
          state = false;
        }
      });

      if (window.innerWidth >= 1000) {
      } else {
      }

      //--------------------------------------------------------
      // Smooth Scroll
      //--------------------------------------------------------
      $(window).on("load", function () {
        var arr = ['a[href^="#"]'];
        var str = arr.join(",");

        $(str).on("click", function () {
          var offset = 0;
          var speed = 800;
          var href = $(this).attr("href");
          var target = $(href == "#" || href == "" ? "html" : href);
          var position = target.offset().top + offset - headerHeight;
          $("body, html").animate(
            { scrollTop: position },
            speed,
            "easeInOutQuart"
          );
          return false;
        });
      });
    } //commonResize

    //--------------------------------------------------------
    // PAGETOP
    //--------------------------------------------------------
    var topBtn = $(".pagetop");
    topBtn.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 160) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });

    //スクロールしてトップ
    topBtn.click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        1500,
        "easeInOutQuart"
      );
      return false;
    });
  });
}