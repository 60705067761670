export function module_works() {
	$(function () {
		worksContent();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;
		
		$(window).on("resize", function () {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function () {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if (winWidth != winWidth_resized || winHeight != winHeight_resized) {
					worksContent();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//リサイズ用関数
		function worksContent() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			//Slick SlideShow
			$(".worksSlider").slick({
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
        pauseOnHover: true,
        fade: true,
        asNavFor: ".worksThumbnail",
      });

			$(".worksThumbnail").slick({
        slidesToShow: 5, // サムネイルの表示数
        slidesToScroll: 1,
        asNavFor: ".worksSlider", // メイン画像と同期
        focusOnSelect: true, // サムネイルクリックを有効化
        responsive: [
          {
            breakpoint: 788,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      });
		}
	});
}